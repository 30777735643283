import React from 'react';

function Pencil(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      {...props}
    >
      <mask
        id='mask0_3920_10191'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='19'
        height='18'
      >
        <rect x='0.25' width='18' height='18' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_3920_10191)'>
        <path
          d='M2.875 18.0017C2.56825 18.0017 2.30406 17.8921 2.08244 17.6728C1.86081 17.4536 1.75 17.1882 1.75 16.8767C1.75 16.5699 1.86081 16.3058 2.08244 16.0841C2.30406 15.8625 2.56825 15.7517 2.875 15.7517H15.625C15.9318 15.7517 16.1959 15.8613 16.4176 16.0806C16.6392 16.2998 16.75 16.5652 16.75 16.8767C16.75 17.1834 16.6392 17.4476 16.4176 17.6693C16.1959 17.8909 15.9318 18.0017 15.625 18.0017H2.875ZM4.75 12.3118H5.67737L11.9024 6.09825L11.4308 5.61956L10.9636 5.15944L4.75 11.3844V12.3118ZM3.625 12.7588V11.1883C3.625 11.0979 3.64012 11.0119 3.67037 10.9301C3.70075 10.8484 3.751 10.7724 3.82113 10.7021L12.0323 2.50988C12.1409 2.40125 12.2642 2.31906 12.4023 2.26331C12.5401 2.20756 12.6826 2.17969 12.8298 2.17969C12.9817 2.17969 13.1263 2.20756 13.2633 2.26331C13.4002 2.31906 13.5269 2.40513 13.6433 2.5215L14.5446 3.43444C14.661 3.54306 14.7451 3.66706 14.797 3.80644C14.849 3.94594 14.875 4.09163 14.875 4.2435C14.875 4.383 14.849 4.52169 14.797 4.65956C14.7451 4.79756 14.661 4.92475 14.5446 5.04113L6.35238 13.2334C6.28212 13.3036 6.20619 13.3551 6.12456 13.3877C6.04281 13.4204 5.95675 13.4368 5.86637 13.4368H4.303C4.10963 13.4368 3.94831 13.3721 3.81906 13.2428C3.68969 13.1135 3.625 12.9522 3.625 12.7588ZM11.9024 6.09825L11.4308 5.61956L10.9636 5.15944L11.9024 6.09825Z'
          fill='#484848'
        />
      </g>
    </svg>
  );
}

Pencil.displayName = 'Pencil';

export default Pencil;
