import React from 'react';

function Delete(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='19'
      height='18'
      viewBox='0 0 19 18'
      fill='none'
      {...props}
    >
      <mask
        id='mask0_3920_10185'
        style={{ maskType: 'alpha' }}
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='19'
        height='18'
      >
        <rect x='0.25' width='18' height='18' fill='#D9D9D9' />
      </mask>
      <g mask='url(#mask0_3920_10185)'>
        <path
          d='M5.73081 15.3743C5.35794 15.3743 5.03875 15.2416 4.77325 14.9761C4.50775 14.7106 4.375 14.3914 4.375 14.0185V4.49931H4.1875C4.02812 4.49931 3.89456 4.44538 3.78681 4.3375C3.67894 4.22962 3.625 4.096 3.625 3.93663C3.625 3.77712 3.67894 3.64356 3.78681 3.53594C3.89456 3.42819 4.02812 3.37431 4.1875 3.37431H7C7 3.19069 7.06469 3.03419 7.19406 2.90481C7.32331 2.77556 7.47975 2.71094 7.66337 2.71094H10.8366C11.0203 2.71094 11.1767 2.77556 11.3059 2.90481C11.4353 3.03419 11.5 3.19069 11.5 3.37431H14.3125C14.4719 3.37431 14.6054 3.42825 14.7132 3.53613C14.8211 3.644 14.875 3.77763 14.875 3.937C14.875 4.0965 14.8211 4.23006 14.7132 4.33769C14.6054 4.44544 14.4719 4.49931 14.3125 4.49931H14.125V14.0185C14.125 14.3914 13.9923 14.7106 13.7268 14.9761C13.4613 15.2416 13.1421 15.3743 12.7692 15.3743H5.73081ZM13 4.49931H5.5V14.0185C5.5 14.0859 5.52162 14.1412 5.56487 14.1844C5.60812 14.2277 5.66344 14.2493 5.73081 14.2493H12.7692C12.8366 14.2493 12.8919 14.2277 12.9351 14.1844C12.9784 14.1412 13 14.0859 13 14.0185V4.49931ZM7.86569 12.7493C8.02506 12.7493 8.15863 12.6954 8.26638 12.5877C8.374 12.4798 8.42781 12.3462 8.42781 12.1868V6.56181C8.42781 6.40244 8.37387 6.26881 8.266 6.16094C8.15825 6.05319 8.02463 5.99931 7.86513 5.99931C7.70575 5.99931 7.57219 6.05319 7.46444 6.16094C7.35681 6.26881 7.303 6.40244 7.303 6.56181V12.1868C7.303 12.3462 7.35688 12.4798 7.46463 12.5877C7.5725 12.6954 7.70619 12.7493 7.86569 12.7493ZM10.6349 12.7493C10.7942 12.7493 10.9278 12.6954 11.0356 12.5877C11.1432 12.4798 11.197 12.3462 11.197 12.1868V6.56181C11.197 6.40244 11.1431 6.26881 11.0354 6.16094C10.9275 6.05319 10.7938 5.99931 10.6343 5.99931C10.4749 5.99931 10.3414 6.05319 10.2336 6.16094C10.126 6.26881 10.0722 6.40244 10.0722 6.56181V12.1868C10.0722 12.3462 10.1261 12.4798 10.234 12.5877C10.3417 12.6954 10.4754 12.7493 10.6349 12.7493Z'
          fill='#484848'
        />
      </g>
    </svg>
  );
}

Delete.displayName = 'Delete';

export default Delete;
