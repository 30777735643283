import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../../ui/Modal';
import Button from '../../ui/Button';
import { ButtonTypes } from '../../ui/Button/button-types';

import './delete-modal.css';

type DeleteModalProps = {
  readonly deleteMessage: () => void;
  readonly onHide: () => void;
  readonly show: boolean;
};

function DeleteModal({
  onHide,
  show,
  deleteMessage
}: DeleteModalProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <Modal.Root
      animation={true}
      keyboard={true}
      onHide={onHide}
      show={show}
      className='delete-message-modal'
    >
      <Modal.Header onHide={onHide}>
        {t('messages.danger.delete-title')}
      </Modal.Header>
      <Modal.Body>
        <p>{t('messages.danger.delete-confirmation-message')}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onHide}
          type='button'
          buttonType={ButtonTypes.Tertiary}
          style={{
            width: 'calc(50% - 8px)',
            height: 44
          }}
        >
          {t('messages.danger.nevermind')}
        </Button>

        <Button
          onClick={() => {
            deleteMessage();
            onHide();
          }}
          type='button'
          buttonType={ButtonTypes.Primary}
          style={{
            width: 'calc(50% - 8px)',
            height: 44
          }}
        >
          {t('messages.danger.delete-confirm')}
        </Button>
      </Modal.Footer>
    </Modal.Root>
  );
}

DeleteModal.displayName = 'DeleteModal';

export default DeleteModal;
